<template>
  <div class="container-fluid pb-3" style="margin-top:15px">
  <vue-progress-bar></vue-progress-bar>
    <div class="row">
      <div class="col-lg-12">
        <template v-if="ticketItem"> <!--  && granted_ticket_show == 1" -->
          <div class="col">
            <div class="blog-header">
              <h1 class="mt-2">{{ ticketItem.title }}
                <span v-if="ticketItem.isClosed === false" class="float-right"> <!-- && granted_ticket_add_edit == 1 -->
                  <button class="btn btn-danger btn-sm" @click="onCloseTicket()">
                    <i class="fe fe-x-square mr-2"></i>Zamknij zgłoszenie
                  </button>
                </span>
              </h1>
              <hr/>
              <div class="text-muted d-flex justify-content-between">
                <div class="align-self-center">
                  <small class="mr-3"><i class="fe fe-box mr-2"></i>{{ ticketItem.textNumber }}</small>
                  <small class="mr-3"><i class="fe fe-layers mr-2"></i>{{ ticketItem.category.title }}</small>
                  <small class="mr-3"><i class="fe fe-calendar mr-2"></i>{{ ticketItem.createdAt.substr(0, 10) }}</small>
                  <small class="mr-3"><i class="fe fe-user mr-2"></i>{{ ticketItem.createdBy.fullName }}</small>
                </div>
                <router-link class="btn btn-primary btn-sm" to="/tickets"><i class="fe fe-chevron-left mr-2"></i>Powrót</router-link>
              </div>
              <template v-if="ticketItem.isClosed === false && ticketItem.isRedirected === false">
                <div class="alert alert-success alert--grouped mt-3">
                <i class="fe fe-info mr-2"></i>Status zgłoszenia: <strong>Otwarty</strong>
                </div>
              </template>
              <template v-else-if="ticketItem.isClosed === false && ticketItem.isRedirected === true">
                <div class="alert alert-info alert--grouped mt-3">
                <i class="fe fe-fast-forward mr-2"></i>Status zgłoszenia: <strong>Przekazany do eksperta</strong>
                </div>
              </template>
              <template v-else>
                <div class="alert alert-warning alert--grouped mt-3">
                <i class="fe fe-x-circle mr-2"></i>Status zgłoszenia: <strong>Zamknięty</strong> przez {{ ticketItem.closedBy.fullName }} {{ ticketItem.closedAt.substr(0, 10) }}
                </div>
              </template>
              <hr/>
            </div>
            <div class="blog-content" v-html="ticketItem.message"></div>
            <div class="blog-footer">
              <!-- attachments -->
              <template v-if="ticketItem.ticketAttachments && ticketItem.ticketAttachments.length > 0">
                <hr/>
                <div class="card-deck">
                <template v-for="image in ticketItem.ticketAttachments">
                  <div class="card card--flex my-2" :key="image.uuid">
                    <div class="card-body d-flex align-items-center align-self-center">
                      <a :href="dataBaseUrl + '/media/ticket/' + image.filename" target="_blank">
                        <img :src='`${dataBaseUrl}/media/ticket/${image.filename}`' class="img-fluid p-2" style="max-height:100px" alt="attachment">
                      </a>
                    </div>
                  </div>
                </template>
                </div>
              </template>
              <!-- anwsers -->
              <template v-if="ticketItem.ticketAnswers && ticketItem.ticketAnswers.length > 0">
                <hr/>
                <div class="list-group">
                  <div class="list-group-item list-group-item-heading list-group-item-info">
                    <h3 class="mt-2">Odpowiedzi na zgłoszenie</h3>
                  </div>
                  <template v-for="answer in ticketItem.ticketAnswers">
                    <div :key="answer.uuid" class="list-group-item">
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-2">
                          <template v-if="ticketItem.createdBy.id == answer.createdBy.id">
                            <i class="fe fe-corner-up-left text-primary mr-2"></i>
                          </template>
                          <template v-else>
                            <i class="fe fe-corner-up-right text-warning mr-2"></i>
                          </template>
                          {{ answer.createdBy.fullName }}
                        </h5>
                        <small>{{ answer.createdAt.substr(0, 10) }}</small>
                      </div>
                      <p class="m-0">{{ answer.answer }}</p>
                      <template v-if="answer.knowledgeBaseContent && answer.knowledgeBaseContent.length > 0">
                        <hr/>
                        <h4 class="mt-2 pb-2">Artykuły bazy wiedzy</h4>
                        <ul class="m-0">
                          <template v-for="article in answer.knowledgeBaseContent">
                            <li :key="article.uuid"><router-link class="text-primary" :to="'/knowledge_base/show/' + article.uuid">{{ article.title }}</router-link></li>
                          </template>
                        </ul>
                      </template>
                    </div>
                  </template>
                </div>
              </template>
              <!-- reply form -->
              <template v-if="ticketItem.isClosed === false "> <!-- && granted_ticket_add_edit == 1 -->
                <hr/>
                  <div class="form-group">
                    <label for="ticket-message">Wiadomość</label>
                    <textarea v-model="anwserReply" class="form-control" id="ticket-message" rows="3" placeholder="Podaj treść odpowiedzi"></textarea>
                  </div>
                  <div class="form-group text-right">
                    <button class="basic-info__add-btn btn-sm" @click="onSendAnswser()">Wyślij odpowiedź</button>
                  </div>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="hasError" class="alert alert-warning alert--grouped mt-3">
            <i class="fe fe-alert-triangle mr-2"></i>
            Uwaga!<br/>Zasób nie istnieje lub nie masz do niego dostępu.
          </div>
          <div v-else class="loader"></div>
        </template>
      </div>
      <!-- <custom-alert></custom-alert> -->
    </div>
    <div v-if="isUpdated" class='loader-container'>
        <div  class="loader loader--fixed"></div>
    </div>
  </div><!-- /container-fluid -->
</template>

<script>
import { baseUrl } from '@/axios-config';
// import Alert from '../Alert.vue';
import { mapActions, mapState } from 'vuex';
export default {
  components: {
    // 'custom-alert': Alert,
  },
  data: function() {
    return {
      granted_ticket_show: 0,
      granted_ticket_add_edit: 0,
      hasError: false,
      dataBaseUrl: baseUrl,
      // anwserReply: '',
      isUpdated: false,
    };
  },
  created: function() {
    // const token = localStorage.getItem('token');
    // const decoded = token !== null ? jwtDecode(token) : null;

    // if (decoded !== null) {
    //   if (decoded.roles.includes('ROLE_AS_TICKET_LIST_SHOW')) {
    //     this.granted_ticket_show = 1;
    //   } else {
    //     this.granted_ticket_show = 0;
    //   }
    //   if (decoded.roles.includes('ROLE_AS_TICKET_ADD_EDIT')) {
    //     this.granted_ticket_add_edit = 1;
    //   } else {
    //     this.granted_ticket_add_edit = 0;
    //   }
    // } else {
    //   this.$router.push('login');
    // }
    this.getTicket();
  },
  computed: {
    ...mapState('tickets', ['ticketItem']),
    anwserReply: {
      get () {
        return this.$store.state.anwserReply
      },
      set (value) {
        this.$store.commit('tickets/UPDATE_ANSWER_REPLY', value)
      }
    }
  },
  beforeDestroy(){
    this.$store.commit('tickets/SET_TICKET_ITEM')
  },
  methods: {
    ...mapActions('tickets', ['getTicket']),
    onSendAnswser() {
      this.$Progress.start();
      this.isUpdated = true;
      this.$store.dispatch('tickets/onSendAnswer')
      .then(()=>{
        this.$Progress.finish();
      })
      .catch(error=>{
        console.error('Error:', error);
        this.$Progress.fail();
      })
      .finally(()=>{
        this.isUpdated = false;
      });
    },
    onCloseTicket () {
      this.$Progress.start();
      this.isUpdated = true;
      this.$store.dispatch('tickets/onCloseTicket')
      .then(()=>{
        this.$Progress.finish();
        this.$router.push('/tickets');
      })
      .catch(error=>{
        console.error('Error:', error);
        this.$Progress.fail();
      })
      .finally(()=>{
        this.isUpdated = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables";
@import "../../assets/css/scoped-forms";
</style>
